import { apiCall } from "../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import { RFFORMINFO, RF_TC_FORM_FIELD } from "../../../constants/apiRoutes";

/**
 * list RFFormInfos.
 * @returns {Promise} - A promise that resolves with the list of RFFormInfos or rejects with an error.
 * @param props
 */
export const listRFFormInfo = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset == 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${RFFORMINFO}/Filter`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return {
    RFFormInfo: response?.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create RFFormInfo.
 * @returns {Promise} - A promise that resolves with the creation of Form Info or rejects with an error.
 * @param data
 */
export const createRFFormInfo = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RFFORMINFO}`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update RFFormInfo.
 * @returns {Promise} - A promise that resolves with the update of Form Info or rejects with an error.
 * @param data
 */
export const updateRFFormInfo = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RFFORMINFO}/${data?.ossiTcFormId}`,
    method: "PUT",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * delete RFFormInfo.
 * @returns {Promise} - A promise that resolves with the update of Form Info or rejects with an error.
 * @param data
 */
export const deleteRFFormInfo = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RFFORMINFO}?tenant_id=${data?.tenantId}&ossi_tc_form_id=${data?.ossiTcFormId}&uc_ossi_appl_id=${data?.ucOssiApplId}&uc_ossi_app_ver=${data?.ucOssiAppVer}&uc_ossi_srtseq=${data?.ucOssiSrtseq}`,
    method: "DELETE",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * get RFFormInfo.
 * @returns {Promise} - A promise that resolves with the RFFormInfo or rejects with an error.
 * @param data
 */
export const getRFFormInfo = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RFFORMINFO}?tenant_id=${data?.tenantId}&uc_ossi_form_id=${data?.ossiTcFormId}&uc_ossi_appl_id=${data?.ucOssiApplId}&uc_ossi_app_ver=${data?.ucOssiAppVer}&uc_ossi_srtseq=${data?.ucOssiSrtseq}`,
    method: "GET",
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * Create RFFormInfo.
 * @returns {Promise} - A promise that resolves with the creation of Form Info or rejects with an error.
 * @param data
 */
export const getRFTCFormField = async ({
  moduleName,
  isServiceWorker,
  listController,
  version,
  formId,
  fieldId,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RF_TC_FORM_FIELD}/Filter?UcOssiAppVer=${version ? version : ""}&OssiTCFormId=${formId ? formId : ""}&OssiTCFldId=${fieldId ? fieldId : ""}`,
    method: "GET",
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * User Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
